@import url(//fonts.googleapis.com/earlyaccess/opensanshebrew.css);

body,p,div,span,h1,h2,h3,h4,h5,h6,a{
  font-family: 'Open Sans Hebrew', sans-serif;
}
.App-header{
  margin-bottom: 24px;
}
.loading_sec{
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 9999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navbar-fixed {
  background-color:#030140;
  position: fixed;
  height: 114px;
  .nav-wrapper {
    margin: 0px 90px 0px 60px;
    padding: 22px 0px;
  }
  ul {
    display: flex;
    justify-content: center;
    li {
      a {
        font-size:16px;
        text-decoration: none;
        &:hover {
          color: white;
          font-weight: 500;
          text-decoration: underline;
          text-decoration-color: #04E0C4;
          text-decoration-thickness: 4px;
          text-underline-offset: 15px;
        }
      }
    }
    li:last-child{
      width: 0;
    }
  }
}
.sidenav.sidenav-fixed {
  right: 0 !important;
  left: auto !important;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  position: fixed;
  /* z-index: 0; */
  top: 115px;
  border-radius: 12px;
  padding: 20px;
  box-shadow: none;
  width: 280px;
}
.contentSection {
  position:relative;
  top:120px;
}
.titleClass{
  text-align: right;
  font-weight: bold;
  color:#030140;
  font-size:20px;
  margin-bottom:20px;
}
#helpdesk-line-chart1 {
  height: 40px;
  width: 200px;
  margin: auto 0 0 auto;
}
.bg-white {
  background:white;
}
.card-panel{
  &.with-background {
    background: #F8F9FB 0% 0% no-repeat padding-box;
  }
  box-shadow:none;
  border-radius:10px;
  transition: box-shadow .25s, -webkit-box-shadow .25s;
    padding: 32px;
    margin: 0.5rem 0 1rem 0;
}
.logo-tag{
  float: right;
  line-height: 22px;
  font-size: 15px;
  font-family: sans-serif;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.pad-2 {
  padding-right: 1px;
}
.qv-object .qv-object-title {
  display:inline;
  margin:0px !important;
  text-align: center;
  padding-top:20px;
}
#map1 {
  .qv-object .qv-object-title .qv-object-title-text {
    text-align: right;
  }
  
}
.qv-object-subtitle, .qv-object .qv-object-footnote {
  text-align: center;
}
.card {
  box-shadow: none;
}
.h-100p{
  height:100%;
}
.h-700{
  height:760px;
}
.h-200 {
  height:200px;
}
.h-500 {
  height:500px;
}
.sidebar_box{
  text-align: center;
  height: 80vh;
  background-color: #030140;
  color: white;
  border-radius: 20px;
  .menu_wrap{
    display: flex;
    flex-direction: column;
    margin-top: 25px;
    a{
      margin: 6px 10px;
      text-decoration: none;
      background-color: white;
      color: black;
      cursor: pointer;
      padding: 6px 10px;
      border-radius: 5px;
      border: none;
      outline: none;
      transition: .4s;
      font-weight: 600;
      &:hover{
        background-color: #dfe0e2;
      }
    }
  }
  .avatar{
    padding-top: 19px;
  }
  .user_name{
    margin: 17px 0 14px 0;
    font-size: 20px;
    color: #FFFFFF;
  }
  .email{
    font-size: 12px;
    margin-bottom: 19px;
    opacity: 0.65 ;
  }
  .icons_box{
    margin-bottom: 22px;
    span{
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      height: 44px;
      width: 44px;
      background: #73728e;
      padding: 13.08px;
      cursor: pointer;
      margin: 0 10px;
      svg{
        height: 20px;
        width: 20px;
      }
    }
  }
  .analysis{
    font-size: 20px;
    text-align: right;
    margin-right: 33px;
    margin-bottom: 11px;
  }
  .date{
    font-size: 16px;
    text-align: right;
    margin-right: 33px;
    opacity: 0.65 ;
  }
  .menu_items{
    margin-top: 70px;
    text-align: right;
    &.share{
      margin-top: 70px;
    }
    & > a{
      margin-bottom: 10px;
      font-size: 16px;
      cursor: pointer;
      display: block;
      text-decoration: none;
      color: white;
      span{
        position: relative;
        top: 5px;
        display: inline-block;
        height:29px;
        width: 5px;
        background: transparent;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        margin-left: 18px;
      }
      svg{
        margin-left: 10px;
        font-size: 25px;
      }
    }
    & > a.active{
      color: #04E0C4;
      span{
        background: #04E0C4;
      }
      svg{
        color: #04E0C4;
      }
    }
  }
}

.qv-object-map .sbVertical>.sidebar>.legend {
  border: 0;
  margin-left: 10px;
}

@media only screen and (min-width: 1600px) and (max-width: 1750px){
  .sidenav.sidenav-fixed {
    width: 268px !important;
    z-index: 99999;
  }
  .navBar .search_box {
    left: 223px;
  }
  .navBar .search_input.active {
    width: 222px;
    padding-right: 38px;
  }
  .chart_contnt{
    .user_icn_box{
      img:first-child{
        height: 21px;
        width: 21px;
        right: 180px!important;
      }
      img:nth-child(2){
        height: 29px;
        width: 29px;
        right: 89px!important;
      }
      img:last-child{
        height: 37px;
        width: 37px;
        left: 20px!important;
      }
    }
  }
}

@media only screen and (min-width: 1367px) and (max-width: 1600px){
  .menu_items.share{
    margin-top: 40px!important;
  }
  .sidenav.sidenav-fixed{
    width: 235px!important;
    z-index: 99999;
  }
  #helpdesk-line-chart5{
    width: 185px!important;
  }
  .qv-st-sorting-indicator:before{
    left: 0px!important;
    top: -19px!important;
  }
  .chart_contnt{
    .user_icn_box{
      img:first-child{
        height: 21px;
        width: 21px;
        right: 154px!important;
      }
      img:nth-child(2){
        height: 29px;
        width: 29px;
        right: 57px!important;
      }
      img:last-child{
        height: 37px;
        width: 37px;
        left: 50px!important;
      }
    }
  }
  .navBar .nav-wrapper {
    margin: 0px 30px 0px 30px;
  }
  .navBar ul li a {
    font-size: 14px;
  }
  .pad-2 {
    padding: 10px;
    padding-left: 5px;
  }
  .sidebar_col{
    padding: 15px!important;
  }
  .navBar .search_box {
    left: 247px;
  }
  #map1{
    min-width: 330px!important;
  }
  .card.h-250 .qv-object-content-container > div > div canvas:first-child{
    width: 287px!important;
    height: 221px!important;
  }
  #dough3 .qv-object-content-container > div > div canvas:first-child{
    width: 287px!important;
    height: 173px!important;
  }
  #dough3 header h2{
    margin:15px!important;
  }
  #dough1, #dough2, #dough3, #dough4, #dough5, #dough6, #dough7, #dough8{
    min-width: 280px!important;
  }
  .sidebar_box .user_name, .sidebar_box .analysis{
    font-size: 17px;
  }
  .sidebar_box .menu_items > a{
    font-size: 14px;
  }
  .nav_bar_box .search_input.active{
    right: 4%!important;
  }
  .custom_dgrm{
    font-size: 11px!important;
  }
}
.mobile_poster{
  display: none;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  z-index: 999999999;
  div{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    h2{
      font-weight: 600;
      text-align: center;
      padding: 0 20px;
    }
  }
}
@media only screen and (min-width: 300px) and (max-width: 700px) {
  .mobile_poster{
    display: block;
  }
  .App{
    overflow-x: hidden;
  }
}
@media only screen and (min-width: 1270px) and (max-width: 1366px) {
  #SvKHjN_content table thead tr:nth-child(1) th:nth-child(4) {
    position: absolute;
    left: 32%!important;
    top: 2.3%;
    width: 50px;
}
.qv-st-sorting-indicator:before{
  left: 0px!important;
  top: -20px!important;
}
  .custom_dgrm p.arrow_icons img {
    height: 12px!important;
    width: 12px!important;
    margin: 5px 17px 5px -6px!important;
  }
  .custom_dgrm p img{
    height: 20px!important;
    width: 20px!important;
  }
  .custom_dgrm{
    font-size: 9px!important;
  }
  .sidenav.sidenav-fixed{
    width: 218px!important;
    z-index: 9999;
  }
  #helpdesk-line-chart5{
    width: 172px!important;
  }
  .nav_bar_box{
    padding: 0px 10px 0px 10px!important;
  }
  .logo-tag{
    font-size: 12px!important;
  }
  .nav_bar_box .center_menus a{
    font-size: 13px!important;
  }
  .nav_bar_box .search_input.active{
    right: 1%!important;
  }
  .chart_contnt{
    .user_icn_box{
      img:first-child{
        height: 21px;
        width: 21px;
        right: 136px!important;
      }
      img:nth-child(2){
        height: 29px;
        width: 29px;
        right: 42px!important;
      }
      img:last-child{
        height: 37px;
        width: 37px;
        left: 65px!important;
      }
    }
  }
  .navBar .nav-wrapper {
    margin: 0px 15px 0px 15px;
  }
  .navBar ul li a {
    font-size: 12px;
  }
  .pad-2 {
    padding: 2px;
  }
  .sidebar_col{
    padding: 1px!important;
  }
  .navBar .search_box {
    left: 183px;
  }
  #map1{
    min-width: 330px!important;
  }
  .card.h-250 .qv-object-content-container > div > div canvas:first-child{
    width: 198px!important;
    height: 149px!important;
  }
  // .card.h-250 canvas{
  //   &[data-key="slices"]{
  //     width: 198px!important;
  //     height: 149px!important;
  //   }
  // }
  #dough3 .qv-object-content-container > div > div canvas:first-child{
    width: 287px!important;
    height: 173px!important;
  }
  #dough3 header h2{
    margin:15px!important;
  }
  #dough1, #dough2, #dough3, #dough4, #dough5, #dough6, #dough7, #dough8{
    min-width: 200px!important;
  }
  .sidebar_box .user_name, .sidebar_box .analysis{
    font-size: 14px;
  }
  .sidebar_box .menu_items > a{
    font-size: 12px;
  }
  .sidebar_box .menu_items{
    margin-top: 80px;
  }
}

.qv-collapsed-listbox .title-wrapper .title, .lui-select {
  text-align: right;
}

#helpdesk-line-chart{
  .qv-collapsed-listbox{
    .title-wrapper{
      &::before{
        font-family: "Font Awesome 5 Free";
        content: "\f078";
        font-weight: 900;
        padding-left: 7px;
      }
    }
  }
}

#LqJ_content, #WYWvWf_content, #WDPLP_content{
  .sn-kpi{
    .sn-kpi-glyph-wrapper{
      &[data-testid="ArrowDropUp"]{
        &::before{
          content: " ";
          background-image: url('./images/up_arrow.png');
          background-size: contain;
          background-repeat: no-repeat;
          height: 20px;
          width: 20px;
          margin: 0 9px;
          display: inline-block;
        }
        svg{
          display: none!important;
        }
      }
      &[data-testid="ArrowDropDown"]{
        &::before{
          content: " ";
          background-image: url('./images/down_arrow.png');
          background-size: contain;
          background-repeat: no-repeat;
          height: 20px;
          width: 20px;
          margin: 0 9px;
          display: inline-block;
        }
        svg{
          display: none!important;
        }
      }

  }
  }
}

#pbYuKUe_content, #vpPcFzy_content, #sgrft_content, #PLRHEJ_content,#AVqL_content, #TuZkfy_content, #bJBAjqJ_content{
  .sn-kpi{
    .sn-kpi-glyph-wrapper{
        &[data-testid="ArrowDropUp"]{
          &::before{
            content: " ";
            background-image: url('./images/up_arrow.png');
            background-size: contain;
            background-repeat: no-repeat;
            height: 20px;
            width: 20px;
            margin: 0 9px;
            display: inline-block;
          }
          svg{
            display: none!important;
          }
        }
        &[data-testid="ArrowDropDown"]{
          &::before{
            content: " ";
            background-image: url('./images/down_arrow.png');
            background-size: contain;
            background-repeat: no-repeat;
            height: 20px;
            width: 20px;
            margin: 0 9px;
            display: inline-block;
          }
          svg{
            display: none!important;
          }
        }

    }
  }
}
#SvKHjN_title{
  border: 0;
  padding: 20px;
  h1{
    text-align: start;
  }
} 
.qv-st-sorting-indicator:before{
  left: 17px;
  top: -18px!important;
}

// .qv-st-interactive:not(.qv-st-selections-active):not(.qv-st-reordering-active) .qv-st-header:not(.qv-st-custom-header) .qv-st-header-cell-search.qv-st-header-cell-search-open{
//   background-color: transparent;
//   color: #595959;
// }
#SvKHjN_content{
  table{
    border-collapse: separate;
    thead{
      background: #F8F9FB;
      tr{
        border: 0;
        th{
          border: 0;
          width: 105px;
          padding: 13px 18px;
        }
      }
      tr:nth-child(1){
        th:nth-child(4){
          position: absolute;
          left: 46%;
          top: -2px;
          width: 50px;
        }
      }
      tr:nth-child(2){
        background: white;
      }
    }
    tbody{
      background: #F8F9FB;
      tr{
        margin-bottom: 10px;
        border: 0;
        td{
          border: 0;
          padding: 8px 0;
          .qv-st-value{
            background: white;
            padding: 12px 29px; 
          }
        }
      }
      tr:nth-child(1){
        td{
          padding-top: 16px;
        }
      }
    }
  }
}

#helpdesk-line-chart5{
  width: 224.35px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  &::before{
    font-family: "Font Awesome 5 Free";
    content: "\f078";
    font-weight: 900;
    padding-left: 8px;
    position: absolute;
    top: 30%;
    left: 1%;
    color: black;
    z-index: 9999;
  }
  .lui-select{
    background-color: white;
    background-image: none;
    padding: 1px 10px;
    height: 32px;
  }
}
#SvKHjN_content{
  direction: rtl;
  *{
    direction:rtl;
  }
}

#tab2{
  header{
    h1{
      text-align: start;
      display: flex;
      justify-content: space-between;
      margin: 20px 10px!important;
      &::before{
        content: 'שינוי המכר %';
        font-weight: normal;
        font-size: 14px;
      }
      div{
        &::after{
          content: 'לפי מספר תושבים';
          font-weight: normal;
          display: block;
          margin: 10px 0;
          font-size:13px;
        }
      }
    }
  }
}

#HCJeck_title{
  h1{
    text-align: start;
    display: flex;
      justify-content: space-between;
      margin: 20px 10px!important;
      &::before{
        content: 'שינוי הפדיון הממוצע %';
        font-weight: normal;
        font-size: 14px;
      }
      div{
        &::after{
          content: 'לפי מספר תושבים';
          font-weight: normal;
          display: block;
          margin: 10px 0;
          font-size:15px;
        }
      }
  }
}

#Hjgmd_title{
  h1{
    text-align: start;
    margin: 15px 5px!important;
  }
}

.custom_dgrm{
  height: 180px;
  background-color: #F8F9FB;
  margin-top: 45px;
  display: flex;
  flex-wrap: wrap;
  visibility: hidden;
  p{
    direction: rtl;
    display: flex;
    font-weight: 600;
    width: 100%;
    img{
      height: 30px;
      width: 30px;
      margin: 5px 15px;
    }
  }
  p.first{
    align-items: center;
    margin-top: 30px;
  }
  p.arrow_icons{
    align-items: center;
    span{
      max-width: 72px;
    }
    img{
      height: 20px;
      width: 20px;
      margin: 5px 23px 5px -6px;
      float: right;
    }
  }
}

body{
  &.loaded{
    .custom_dgrm{
      visibility: visible;
    }
    .chart_contnt{
      .user_icn_box{
        visibility: visible;
      }
    }
    #helpdesk-line-chart1{
      &::before{
        visibility: visible;
      }
    }
  }
}

#aSVqJq_content{
  canvas:nth-child(3){
    &::before{
      content: '';
      background-image: url('./images/up_arrow.png');
    }
  }
}

.chart_contnt{
  position: relative;
  .user_icn_box{
    position: absolute;
    top: 39%;
    left: 51%;
    width: 150px;
    visibility: hidden;
    img:first-child{
      height: 21px;
      width: 21px;
      position: relative;
      right: 204px;
    }
    img:nth-child(2){
      height: 29px;
      width: 29px;
      position: relative;
      right: 107px;
    }
    img:last-child{
      height: 36px;
      width: 36px;
      position: relative;
      left: 0px;
    }
  }
}
body{
  overflow-x: hidden;
  direction: rtl;
}
.contentSection{
  .row{
    justify-content: flex-end;
  }
}

.card{
  border: 0;
}
.row{
  margin: 0;
}

.col-10{
  &.contentSecColumn{
    width: 85.333333%;
    margin-top: 90px;
    z-index:0;
  }
}
.contentSecColumnFixed {
  width: 85.333333%;
  background: white;
  position:fixed;
  top:90px;
  padding-top:20px;
  z-index:100;
  #clearButton {
    button {
      border-radius:5px;
    }
  }
}

.nav_bar_box{
  background-color: #030140;
  justify-content: space-between;
  color: white;
  height: 114px;
  padding: 0px 60px 0px 90px;
  margin-bottom: 24px;
  a{
    color: white;
  }
  .center_menus{
    display: flex;
    justify-content: center;
    align-items: center;
    a{
      font-size:16px;
      text-decoration: none;
      &:hover, &.active {
        color: white;
        font-weight: 500;
        text-decoration: underline;
        text-decoration-color: #04E0C4;
        text-decoration-thickness: 4px;
        text-underline-offset: 15px;
      }
    }
  }
  .search_input{
    display: none;
  }
  .search_input.active{
    display: inline-block;
    position: absolute;
    width: 280px;
    height: 44px;
    border: 0;
    background: #323164;
    color: white;
    top: 31%;
    border-radius: 50px;
    right: 3%;
    text-align: right;
    padding-right: 60px;
    font-size: 20px;
  }
  .search_box{
    z-index: 9;
    visibility: hidden;
    input.active{
      display: inline-block;
    }
    span{
      width: 43px;
      height: 43px;
      border-radius: 50%;
      padding: 10px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      background-color: #73728e;
      cursor: pointer;
      svg{
        font-size: 23px;
      }
    }
  }
}


#share_table_box1{
  #BCvjmzP_title{
    h1{
      text-align: start;
      padding-right: 10px;
    }
  }
  table{
    border-collapse: separate;
    thead{
      background: #F8F9FB;
      tr{
        border: 0;
        th{
          border: 0;
          padding: 13px 18px;
          font-weight: 700;
        }
      }
      tr:nth-child(2){
        background: white;
      }
    }
    tbody{
      background: #F8F9FB;
      tr{
        margin-bottom: 10px;
        border: 0;
        td{
          border: 0;
          padding: 8px 0;
          .qv-st-value{
            background: white;
            padding: 12px 29px; 
          }
        }
      }
    }
  }
}

.dashboard_cnt{
  background: white;
  #jBJxmz_content{
    .qv-media-tool-html{
      & > div:nth-child(5){
        display: flex;
        justify-content: center;
        align-items: center;
        &::before{
          content: " ";
            background-image: url('./images/up_arrw_2x.png');
            background-size: contain;
            background-repeat: no-repeat;
            height: 40px;
            width: 40px;
            margin: 0 9px;
            display: inline-block;
        }
      }
    }
  }
  .qv-object-text-image .qv-media-tool-html{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .col-4{
    padding:10px;
    .card{
      padding: 20px;
      background: #F8F9FB 0% 0% no-repeat padding-box;
      & > div{
        background: white;
      }
      & > .row{
        .col-12 > div{
          direction: ltr;
        }
        .col-12{
          button{
            font-weight: normal!important;
            text{
              font-size: 18px!important;
            }
          }
        }
      } 
    }
  }
}

#sPCbS_content, #nmrPybK_content{
  .sn-kpi{
    .sn-kpi-value{
      flex-wrap: wrap;
      .sn-kpi-ellips-text{
        font-size: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 10px 0;
      }
    }
    .sn-kpi-glyph-wrapper{
      flex-direction: row;
      &[data-testid="ArrowDropUp"]{
        &::before{
          content: " ";
          background-image: url('./images/up_arrow.png');
          background-size: contain;
          background-repeat: no-repeat;
          height: 40px;
          width: 40px;
          margin: 0 9px;
          display: inline-block;
        }
        svg{
          display: none!important;
        }
      }
      &[data-testid="ArrowDropDown"]{
        &::before{
          content: " ";
          background-image: url('./images/down_arrow.png');
          background-size: contain;
          background-repeat: no-repeat;
          height: 40px;
          width: 40px;
          margin: 0 9px;
          display: inline-block;
        }
        svg{
          display: none!important;
        }
      }

   }
  }
}

#rHYJDUw_content{
  .qv-media-tool-html{
    align-items: center;
    &::after{
      content: " ";
      background-image: url('./images/up_arrw_2x.png');
      background-size: contain;
      background-repeat: no-repeat;
      height: 40px;
      width: 40px;
      margin: 10px 9px;
      display: inline-block;
    }
  }
}

#ZJuVyp_content{
  .qv-media-tool-html{
    align-items: center;
    &::after{
      content: " ";
      background-image: url('./images/down_arrw_2x.png');
      background-size: contain;
      background-repeat: no-repeat;
      height: 40px;
      width: 40px;
      margin: 10px 9px;
      display: inline-block;
    }
  }
}

#sec_row_cnt_2, #sec_row_cnt_1{
  display: none;
}

.share_cnt{
  #HyBaQP_content{
    & > div {
      & > canvas[data-key="grid-line"], & > canvas[data-key="zero-line"]{
        display: none;
      }
    }
  }
  #pvtmAEB_content{
    & > div {
      & > canvas[data-key="grid-line"], & > canvas[data-key="zero-line"]{
        display: none;
      }
    }
  }
  #YyJWh_content, #jPtfAz_content{
    .sn-kpi-data{
      justify-content: flex-start;
      padding-top: 20px;
    }
    .sn-kpi-value > .sn-kpi-ellips-text{
      direction: ltr;
      color: black;
      font-size: 36px;
      font-weight: 700;
    }
  }
  #HyBaQP, #pvtmAEB, #YyJWh, #jPtfAz {
    header{
      h1{
        text-align: start;
      }
    }
  }
  background: white;
  .col-6{
    padding:15px;
    .card{
      padding: 20px;
      background: #F8F9FB 0% 0% no-repeat padding-box;
      & > div{
        background: white;
      }
    }
  }
}

#XTVGG_content{
  .sn-kpi-value > .sn-kpi-ellips-text{
    padding: 15px 0;
    text-align: center;
    min-width: 210px;
    border-radius: 6px;
  }
  .sn-kpi-value{
    &[data-color="rgb(255, 0, 53)"]{
      .sn-kpi-ellips-text{
        border: 6px solid #CE1011;
      }
    }
    &[data-color="rgb(45, 255, 0)"]{
      .sn-kpi-ellips-text{
        border: 6px solid #1EBC2A;
      }
    }
  }
}

#RkAYaj_content{
  .sn-kpi-value > .sn-kpi-ellips-text{
    padding: 15px 0;
    text-align: center;
    min-width: 210px;
    border-radius: 6px;
  }
  .sn-kpi-value{
    &[data-color="rgb(255, 0, 53)"]{
      .sn-kpi-ellips-text{
        border: 6px solid #CE1011;
      }
    }
    &[data-color="rgb(45, 255, 0)"]{
      .sn-kpi-ellips-text{
        border: 6px solid #1EBC2A;
      }
    }
  }
}

#HyBaQP_title{
  h1{
    div{
      display: flex;
      justify-content: space-between;
      &::after{
        content: " ";
      background-image: url('./images/up_arrw_2x.png');
      background-size: contain;
      background-repeat: no-repeat;
      height: 50px;
      width: 50px;
      margin: 10px 9px;
      display: inline-block;
      }
    }
  }
}

#pvtmAEB_title{
  h1{
    div{
      display: flex;
      justify-content: space-between;
      &::after{
        content: " ";
      background-image: url('./images/down_arrw_2x.png');
      background-size: contain;
      background-repeat: no-repeat;
      height: 50px;
      width: 50px;
      margin: 10px 9px;
      display: inline-block;
      }
    }
  }
}

.sensitivity_sec{
  #WPJPJB, #NSeNc, #JSmjd{
    header{
      h1{
        text-align: start;
      }
    }
  }
  .card-panel.with-background{
    padding: 32px 16px;
  }
  .cnt_box1{
    .col-9{
      padding-top: 20px;
      #YEhaZk{
        .qv-object-wrapper{
          height: 520px;
          background: #F8F9FB 0% 0% no-repeat padding-box;
          padding: 32px;
          border-radius: 6px;
          & > article{
            background: white;
          }
        }
        #YEhaZk_content{
          padding: 30px 30px 30px 5px;
        }
      }
    }
    .col-3{
      padding: 20px 12px;
    }
    #fjBPL_content,#ARcupCF_content{
      .sn-kpi-data{
        background: #F8F9FB 0% 0% no-repeat padding-box;
        border-radius: 6px;
        flex-direction: column-reverse;
        .sn-kpi-measure-wrapper{
          font-size: 20px!important;
          font-weight: 700;
          color: black;
        }
        .sn-kpi-value-wrapper{
          .sn-kpi-value{
            .sn-kpi-ellips-text{
              font-weight: 700;
              font-size: 50px;
              color: black;
            }
          }
        }
      }
    }
  }
  #fjBPL_content{
    .sn-kpi-data{
      border: 5px solid #1EBC2A;
    }
  }
  .cnt_box2{
    .card{
      .row{
        flex-wrap: wrap;
        .equal_wrap{
          width: 20%;
        }
      }
    }
  }
}

#YwZmmKf{
  padding: 60px 20px;
  background: #F8F9FB;
  table{
    border-collapse: separate;
    thead{
      background: #F8F9FB;
      tr:nth-child(1){
        border: 0;
        th{
          border: 0;
          font-weight: 700;
          color: black;
        }
      }
      tr:nth-child(2){
        margin-bottom: 10px;
        border: 0;
        th{
          border: 0;
          padding: 8px 0;
          .qv-st-value{
            background: white;
            padding: 12px 29px; 
          } 
        }
      }
    }
    tbody{
      background: #F8F9FB;
      tr{
        margin-bottom: 10px;
        border: 0;
        td{
          border: 0;
          padding:0;
          .qv-st-value{
            background: white;
            padding: 12px 29px; 
          } 
        }
      }
    }
  }
}

.promotions{
  .prom_box1{
    .col-6{
      padding: 15px;
    }
    .col-6:nth-child(2){
      .card{
        padding: 28px 28px;
        .row{
          background: white;
          padding: 20px 10px;
        }
      }
    }
    .card{
      padding: 28px 18px;
      border-radius: 10px;
      background: #F8F9FB 0% 0% no-repeat padding-box;
    }
  }
  #zPF{
    background: white;
    header{
      h1{
        text-align: start;
        padding-right: 15px;
      }
    }
  }
  .prom_box2{
    justify-content: flex-start;
    .col-4{
      padding-bottom: 25px;

    }
    .qv-collapsed-listbox{
      height: 58px;
      .title-wrapper{
        &::before{
          font-family: "Font Awesome 5 Free";
          content: "\f078";
          font-weight: 900;
          padding-left: 15px;
        }
      }
    }
  }
  .chart_box_cnt{
    .card-panel.with-background{
      padding: 32px 16px;
    }
  }
  .prom_box3{
    #zmjfZ{
      background: white;
    }
  }
  .promotion_select_box{
    padding: 20px;
    .card-panel.with-background{
      padding-bottom: 5px;
    }
  }
}

.analysis_sec{
  .col-8{
    margin-top: 89px;
    margin-right: 20px;
    width: 64.666667%;
  }
  #helpdesk-line-chart{
    .qv-collapsed-listbox{
      .title-wrapper{
        direction: ltr;
      }
    }
  }
  #clearButton{
    #DateRangePickere8e54681-ae63-4dbe-b618-dbcbb69cc48f{
      background: transparent!important;
      direction: ltr;
    }
  }
  .card-panel.with-background{
    padding: 32px 16px;
  }
  #cWrEN{
    padding: 70px 10px 30px 10px;
  }
  .side_sec_filter{
    padding-top: 100px;
    padding-bottom: 40px;
    width: 19%;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    & > .row > .col-12 .row .col-12:nth-child(3){
      padding: 0;
    }
  }
  //time text heading
  #f968ff13-9f41-4e6a-b785-4b01d882e776_title, #ae846285-02b3-480c-8525-a55abd3584ed_title{
    h1{
      text-align: start;
    }
  }
  // orange time text align
  #ae846285-02b3-480c-8525-a55abd3584ed_content{
    .media-tool-scrollbar{
      & > div > div{
        text-align: end!important;
      }
    }
  }

  //lui single select

  #b0bc2082-1215-43ce-93b2-28e66d993881{
    margin-bottom: 20px;
  }
  #b0bc2082-1215-43ce-93b2-28e66d993881_content{
    text-align: center!important;
    &::before{
      font-family: "Font Awesome 5 Free";
      content: "\f078";
      font-weight: 900;
      padding-left: 8px;
      position: absolute;
      top: 22%;
      left: 5%;
      color: black;
      z-index: 9999;
    }
    .lui-select{
      background-image: none;
      height: 34px;
      width: 90.5%!important;
      margin-bottom: 20px;
    }
  }
  #side_bar_filter{
    .qv-collapsed-listbox{
      .title-wrapper{
        direction: ltr;
        height: 34px;
        &::before{
          font-family: "Font Awesome 5 Free";
          content: "\f078";
          font-weight: 900;
          padding-left: 7px;
        }
      }
    }
  }
}

.dashboard_sales_sec{
  & > .row{
    align-items: flex-start;
   & > .col-8:nth-child(3){
      margin-top: 89px;
      .cnt_box1{
        background: white;
      }
      .card-panel.with-background{
        padding: 26px 20px;
      }
      .col-12:nth-child(1){
        & > div > .row{
          padding: 30px 40px;
          .col-12{
            margin-bottom: 30px;
          }
        }
      }
    }
    & > .col-8:nth-child(4){
      position: relative;
      bottom: 500px;
      .card-panel.with-background{
        padding: 26px 10px;
      }
    }
  }
  .col-8{
    width: 64.666667%;
    margin-right: 15px;
    .cnt_box1{
      .col-6{
        .card{
          padding: 40px 30px;
        }
      }
    }
  }
  #helpdesk-line-chart{
    .qv-collapsed-listbox{
      .title-wrapper{
        direction: ltr;
      }
    }
  }
  .side_sec_filter{
    padding-top: 100px;
    padding-bottom: 40px;
    width: 19%;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    & > .row > .col-12 .row .col-12:nth-child(3){
      padding: 0;
    }
  }
  #side_single_select{
    margin-bottom: 12px;
    .qv-object-content-container{
      & > div{
        text-align: center!important;
        &::before{
          font-family: "Font Awesome 5 Free";
          content: "\f078";
          font-weight: 900;
          padding-left: 8px;
          position: absolute;
          top: 22%;
          left: 5%;
          color: black;
          z-index: 9999;
        }
        .lui-select{
          background-image: none;
          height: 34px;
          width: 90.5%!important;
          margin-bottom: 20px;
        }
      }
    }
  }
  #time_text_sec header, #bottom_comments_sec header{
    h1{
      text-align: start;
    }
  }
  // orange time text align
  #time_text_sec{
    .media-tool-scrollbar{
      & > div > div{
        text-align: end!important;
      }
    }
  }
  #clearButton{
    #DateRangePickerLnVC{
      background: transparent!important;
      direction: ltr;
    }
  }
  #side_bar_filter{
    .qv-collapsed-listbox{
      .title-wrapper{
        direction: ltr;
        height: 34px;
        &::before{
          font-family: "Font Awesome 5 Free";
          content: "\f078";
          font-weight: 900;
          padding-left: 7px;
        }
      }
    }
  }
}

.compare_period_sec,.salesout_sec{
  & > .row > .col-8{
    .row > .col-12 > .card > div{
      table{
        border-collapse: separate;
        thead{
          background: #F8F9FB;
          tr{
            border: 0;
            th{
              border: 0;
              padding: 13px 18px;
              font-weight: 700;
            }
          }
          tr:nth-child(2){
            background: white;
          }
        }
        tbody{
          background: #F8F9FB;
          .qv-st-data-cell-null-value{
            background-color: transparent;
          }
          tr{
            margin-bottom: 10px;
            border: 0;
            td{
              background-color: #F8F9FB!important;
              border: 0;
              padding: 8px 0;
              .qv-st-value{
                background: white;
                padding: 12px 29px; 
              }
            }
          }
          tr:nth-child(1){
            td{
              padding-top: 16px;
            }
          }
        }
      }
    }
  }
  .cnt_table_filter_box{
    padding: 26px!important;
    & > .row{
      background-color: white;
      #table_filter, #table_filter2{
        header{
          h1{
            text-align: start;
          }
        }
      }
    }
  }
  #helpdesk-line-chart1{
    width: 100%;
    article article .qv-object-content-container{
      & > div{
        text-align: center!important;
        &::before{
          font-family: "Font Awesome 5 Free";
          content: "\f078";
          font-weight: 900;
          padding-left: 8px;
          position: absolute;
          top: 15%;
          left: 1%;
          color: black;
          z-index: 9999;
        }
        .lui-select{
          background-image: none;
          margin-bottom: 20px;
        }
      }
    }
  }
  #helpdesk-line-chart2{
    .qv-object-content-container{
      & > div{
        text-align: center!important;
        &::before{
          font-family: "Font Awesome 5 Free";
          content: "\f078";
          font-weight: 900;
          padding-left: 8px;
          position: absolute;
          top: 15%;
          left: 1%;
          color: black;
          z-index: 9999;
        }
        .lui-select{
          background-image: none;
          margin-bottom: 20px;
        }
      }
    }
  }
  .col-8:nth-child(3){
    width: 64.666667%;
    margin-right: 15px;
    margin-top: 89px;
    .cnt_box1{
      .col-12{
        .card{
          padding: 40px 30px;
        }
      }
    }
    .card-panel.with-background{
      padding: 26px 13px;
    }
  }
  #side_bar_filter{
    .qv-collapsed-listbox{
      .title-wrapper{
        direction: ltr;
        height: 34px;
        &::before{
          font-family: "Font Awesome 5 Free";
          content: "\f078";
          font-weight: 900;
          padding-left: 7px;
        }
      }
    }
  }
  #time_text_sec header, #bottom_comments_sec header{
    h1{
      text-align: start;
    }
  }
  .side_sec_filter{
    padding-top: 100px;
    padding-bottom: 40px;
    width: 19%;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    & > .row > .col-12 .row .col-12:nth-child(3){
      padding: 0;
    }
  }
  #side_single_select{
    margin-bottom: 12px;
    .qv-object-content-container{
      & > div{
        text-align: center!important;
        &::before{
          font-family: "Font Awesome 5 Free";
          content: "\f078";
          font-weight: 900;
          padding-left: 8px;
          position: absolute;
          top: 22%;
          left: 5%;
          color: black;
          z-index: 9999;
        }
        .lui-select{
          background-image: none;
          height: 34px;
          width: 90.5%!important;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.salesout_sec{
  #mneyRdS{
    margin-top: 20px;
  }
  #clearButton{
    #DateRangePicker866d937e-e56f-48bf-8ce1-5be093b729b4,#DateRangePicker3a318a1e-cc1b-4222-837c-2b53a3a7e86e{
      background: transparent!important;
      direction: ltr;
    }
  }
  #helpdesk-line-chart{
    .qv-collapsed-listbox{
      .title-wrapper{
        direction: ltr;
      }
    }
  }
}

.branch_rating_sec{
  .branch_box{
    .card-panel.with-background{
      padding: 32px 16px;
      .cnt_box1{
        & > .col-12 > .card{
          padding: 40px 20px;
        }
      }
    }
  }
  #helpdesk-line-chart{
    .qv-collapsed-listbox{
      .title-wrapper{
        direction: ltr;
      }
    }
  }
}

.target_share_Sec{
  .share_box{
    .item1,.item2{
      padding: 32px 18px;
    }
    .item1{
      .cnt_box1{
        .col-12{
          .card{
            padding:20px;
            .row{
              .col-6{
                width: 47%;
                margin: 20px;
                padding: 25px;
                background: #F8F9FB 0% 0% no-repeat padding-box;
                border-radius: 6px;
                & > div{
                  background-color: white;
                }
                #DePWa,#jUMNXr{
                  padding: 20px;
                }
                #gzPYPmj{
                  .sn-kpi{
                    padding-bottom: 15px;
                    .sn-kpi-glyph-wrapper{
                      &[data-testid="ArrowDropUp"]{
                        &::before{
                          content: " ";
                          background-image: url('./images/up_arrow.png');
                          background-size: contain;
                          background-repeat: no-repeat;
                          height: 20px;
                          width: 20px;
                          margin: 0 9px;
                          display: inline-block;
                        }
                        svg{
                          display: none!important;
                        }
                      }
                      &[data-testid="ArrowDropDown"]{
                        &::before{
                          content: " ";
                          background-image: url('./images/down_arrow.png');
                          background-size: contain;
                          background-repeat: no-repeat;
                          height: 20px;
                          width: 20px;
                          margin: 0 9px;
                          display: inline-block;
                        }
                        svg{
                          display: none!important;
                        }
                      }
                
                  }
                  }
                }
              }
            }
          }
        }
      }
    }
    .item2{
      #JJsRB,#jFhtNS{
        padding: 10px 10px 20px 10px;
        header{
          h1{
            text-align: start;
          }
        }
        table{
          border-collapse: separate;
          thead{
            background: #F8F9FB;
            tr{
              border: 0;
              th{
                border: 0;
                padding: 13px 18px;
                font-weight: 700;
              }
            }
          }
          tbody{
            background: #F8F9FB;
            tr{
              margin-bottom: 10px;
              border: 0;
              td{
                border: 0;
                padding: 8px 0;
                .qv-st-value{
                  background: white;
                  padding: 12px 29px; 
                }
              }
            }
          }
        }
      }
    }
  }
}

.share_analyz_sec{
  #helpdesk-line-chart{
    .qv-collapsed-listbox{
      .title-wrapper{
        direction: ltr;
      }
    }
  }
  .col-8{
    margin-top: 89px;
    margin-right: 20px;
    width: 64.666667%;
    .card-panel.with-background{
      padding: 32px 16px;
      .card{
        padding: 30px 20px;
      }
    }
  }
  #side_bar_filter{
    .qv-collapsed-listbox{
      .title-wrapper{
        direction: ltr;
        height: 34px;
        &::before{
          font-family: "Font Awesome 5 Free";
          content: "\f078";
          font-weight: 900;
          padding-left: 7px;
        }
      }
    }
  }
  #time_text_sec header, #bottom_comments_sec header{
    h1{
      text-align: start;
    }
  }
  .side_sec_filter{
    padding-top: 100px;
    padding-bottom: 40px;
    width: 19%;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    & > .row > .col-12 .row .col-12:nth-child(3){
      padding: 0;
    }
  }
}

#XkgsWLS{
  #mxjYDk_title{
    & > h1{
      display: flex;
      flex-direction: row-reverse;
    }
  }
}